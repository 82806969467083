import React from 'react';
import styled from 'styled-components';
import SEO from "../components/molecules/SEO";
import Container from "../components/atoms/Container";
import { Link } from "gatsby";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.yellow2};
  color: ${props => props.theme.colours.black};
  padding: 4rem 0;
`;

const Inner = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 10rem calc(100 / 12 * 1% + 2rem);
    p {
      max-width: 23rem;
    }
  }
  .button {
    margin: 1rem 1rem 0 0;
  }
`;

function ThankYouPage() {
  return (
    <>
      <SEO title="Thank you" />
      <Holder>
        <Container>
          <Inner>
            <h1>Thank you</h1>
            <p>You’ll receive a confirmation email and receipt shortly. In the meantime, please set up your profile <a href="https://airtable.com/shrWtCHhsiAWC0hya" target="_blank" rel="noopener noreferrer">here</a> and we'll be in touch within 48 hours once we've activated your talent listing in our servers.</p>
            <div><a href="https://airtable.com/shrWtCHhsiAWC0hya" target="_blank" rel="noopener noreferrer" className="button large">Set up your profile</a><Link className="button large hover-to-purple-alt" to="/sign-up">Sign up another pet</Link></div>
          </Inner>
        </Container>
      </Holder>
    </>
  )
}

export default ThankYouPage;